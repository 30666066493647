import http from "@/services/http";
import { getSERPlusToken } from "@/util/tokenHelpers";

const getProfile = async () => {
  const token = getSERPlusToken();

  const url = `${process.env.VUE_APP_SERPLUS_API_URL}/api/v1/users/me`;
  var headers = {
    Authorization: "Bearer " + token,
  };

  try {
    const response = await http.get(url, {}, headers);
    if (response.data?.language?.value) {
      const locale = response.data.language.value.slice(0, 2);
      localStorage.setItem("locale", locale);
    }
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error,
    };
  }
};

const updateProfile = async (data) => {
  const token = getSERPlusToken();

  const url = `${process.env.VUE_APP_SERPLUS_API_URL}/api/v1/users/me`;
  var headers = {
    Authorization: "Bearer " + token,
  };

  try {
    const response = await http.put(url, data, headers);
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error,
    };
  }
};

export default { getProfile, updateProfile };
