import Vue from "vue";
import VueI18n from "vue-i18n";

import en from "vuetify/lib/locale/en";
import es from "vuetify/lib/locale/es";
import pt from "vuetify/lib/locale/pt";

Vue.use(VueI18n);

const messages = {
  en: {
    ...require("@/locales/en.json"),
    $vuetify: en,
  },
  es: {
    ...require("@/locales/es.json"),
    $vuetify: es,
  },
  pt: {
    ...require("@/locales/pt.json"),
    $vuetify: pt,
  },
};

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "es",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "es",
  messages,
});

export default i18n;
