import Vue from "vue";
import VueMoment from "vue-moment";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import CountryFlag from "vue-country-flag";
import HighchartsVue from "highcharts-vue";
import Highcharts from "highcharts";
import itemInit from "highcharts/modules/item-series";
import Vue2Filters from "vue2-filters";
import VueSweetalert2 from "vue-sweetalert2";

Vue.config.productionTip = false;

Vue.component("country-flag", CountryFlag);
itemInit(Highcharts);
Vue.use(HighchartsVue);
Vue.use(VueMoment);
Vue.use(Vue2Filters);

import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);

Vue.filter("nohtml", function (value) {
  return value ? value.replace(/(<([^>]+)>)/gi, "") : "";
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
