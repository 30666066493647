<template>
  <div class="pl-12 pr-12 pt-8 d-flex flex-row grey lighten-1" style="height: 207px">
    <div id="imagen">
      <v-img v-if="imageName"
          :src="getImgUrl(imageName)"
          max-width="130"
          class="align-self-center mr-6"
        />
    </div>
    <div class="d-flex flex-column">
    <div class="text-h1 pb-4">{{ title }}</div>
    <div class="text-body-1 pb-4">{{ content }}</div>
    <div>
      <slot name="controls"></slot>
    </div>
  </div>
  </div>

</template>

<script>
export default {
  name: "section-header",
  props: {
    title: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
    imageName: {
      type: String,
      default: "",
    },
  },
  methods: {
    getImgUrl(imageName) {
      var images = require.context('../assets/', false, /\.png$/)
      return images('./' + imageName + ".png")
    }
  },
};
</script>