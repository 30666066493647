var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-navigation-drawer',{staticClass:"pt-4 px-1",attrs:{"app":"","clipped":"","fixed":"","width":"285"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',[_c('v-list-item',{on:{"click":function($event){return _vm.logout()}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-location-exit")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.$t("logout"))+" ")])],1)],1)],1)]},proxy:true}])},[_c('v-list',{attrs:{"dense":"","nav":""}},[_c('v-list-item-group',{model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},_vm._l((_vm.items),function(item){return _c('v-list-group',{key:`${item.title}-group`,class:[
          item.children.length == 0
            ? 'grouped-items grouped-items-no-child menu-item'
            : 'grouped-items menu-item',
          { 'v-list-group--active': _vm.containsPath(item, _vm.$route.path) },
        ],attrs:{"link":"","prepend-icon":item.icon,"append-icon":item.children.length == 0 ? '' : '$expand',"value":_vm.containsPath(item, _vm.$route.path),"no-action":""},on:{"click":function($event){$event.stopPropagation();return _vm.go(item)}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',{staticStyle:{"min-height":"50px"}},[_c('v-list-item-title',{staticClass:"text-body-2"},[_vm._v(_vm._s(item.title))])],1)]},proxy:true}],null,true)},_vm._l((item.children),function(child){return _c('v-list-item',{key:child.id,class:[
            { 'v-list-item--active': _vm.containsPath(child, _vm.$route.path) },
          ],attrs:{"value":child.id,"link":""},on:{"click":function($event){$event.stopPropagation();return _vm.go(child)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(child.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(child.title))])],1)],1)}),1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }