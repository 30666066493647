<template>
  <v-app>
    <top-nav />
    <v-main>
      <v-container fluid class="pa-0">
        <router-view></router-view>
      </v-container>
    </v-main>
    <scroll-to-top />
  </v-app>
</template>

<script>
import ScrollToTop from "@/components/ScrollToTop.vue";
import TopNav from "./TopNav.vue";

export default {
  name: "private-layout",
  components: {
    TopNav,
    ScrollToTop,
  },
};
</script>
<style scoped>
.v-main {
  background-color: #f2f2f2 !important;
}
.container {
  background-color: #f2f2f2 !important;
  padding: 0px;
}
</style>
