<template>
  <!-- expand-on-hover -->
  <v-navigation-drawer app clipped fixed width="285" class="pt-4 px-1">
    <v-list dense nav>
      <v-list-item-group v-model="selected">
        <v-list-group
          v-for="item in items"
          :class="[
            item.children.length == 0
              ? 'grouped-items grouped-items-no-child menu-item'
              : 'grouped-items menu-item',
            { 'v-list-group--active': containsPath(item, $route.path) },
          ]"
          :key="`${item.title}-group`"
          link
          :prepend-icon="item.icon"
          :append-icon="item.children.length == 0 ? '' : '$expand'"
          :value="containsPath(item, $route.path)"
          no-action
          @click.stop="go(item)"
        >
          <template v-slot:activator>
            <v-list-item-content style="min-height: 50px">
              <v-list-item-title class="text-body-2">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="child in item.children"
            :class="[
              { 'v-list-item--active': containsPath(child, $route.path) },
            ]"
            :key="child.id"
            :value="child.id"
            link
            @click.stop="go(child)"
          >
            <v-list-item-icon>
              <v-icon>{{ child.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ child.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <!-- </template> -->
      </v-list-item-group>
    </v-list>

    <template v-slot:append>
      <div>
        <v-list-item @click="logout()">
          <v-list-item-icon>
            <v-icon>mdi-location-exit</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-body-2">
              {{ $t("logout") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import loginService from "@/services/login.service";
import { canEvaluate, getUserRole } from "@/util/tokenHelpers";

export default {
  name: "side-nav",
  data() {
    return {
      selected: null,
      items: [
        {
          id: 1,
          title: this.$t("sidenav.dashboard"),
          path: "/dashboard",
          icon: "mdi-home-outline",
          children: [],
        },
        {
          id: 2,
          title: this.$t("sidenav.trackingList"),
          icon: "mdi-bookmark-outline",
          active: false,
          children: [
            {
              id: 3,
              title: this.$t("sidenav.MyLists"),
              icon: "mdi-eye-off-outline",
              path: "/list-private",
            },
            {
              id: 4,
              title: this.$t("sidenav.publicLists"),
              icon: "mdi-eye-outline",
              path: "/list-public",
            },
          ],
        },
        {
          id: 5,
          title: this.$t("sidenav.proposals"),
          path: "/ideas",
          icon: "mdi-format-list-bulleted",
          children: [],
        },
        {
          id: 6,
          title: this.$t("sidenav.charts"),
          path: "/charts",
          icon: "mdi-chart-pie-outline",
          children: [],
        },
        ...((getUserRole() === "user" && canEvaluate()) ||
        getUserRole() !== "user"
          ? [
              {
                id: 7,
                title: this.$t("sidenav.riskEvaluation"),
                path: "/evaluate",
                icon: "mdi-chart-line",
                children: [],
              },
            ]
          : []),
        {
          id: 8,
          title: this.$t("sidenav.cojuntures"),
          path: "/conjuncture",
          icon: "mdi-file-document-outline",
          children: [],
        },
        {
          id: 9,
          title: this.$t("sidenav.catalogs"),
          icon: "mdi-folder-open-outline",
          active: false,
          children: [
            {
              id: 10,
              title: this.$t("sidenav.actors"),
              icon: "mdi-account",
              path: "/catalogs-actor",
            },
            {
              id: 11,
              title: this.$t("sidenav.commissions"),
              icon: "mdi-account-multiple",
              path: "/catalogs-commission",
            },
            {
              id: 12,
              title: this.$t("sidenav.currentLaws"),
              icon: "mdi-book-open-blank-variant",
              path: "/catalogs-law",
            },
          ],
        },
        {
          id: 13,
          title: this.$t("sidenav.settings"),
          icon: "mdi-cog-outline",
          children: [
            {
              id: 14,
              title: this.$t("sidenav.profile"),
              icon: "mdi-account",
              path: "/settings-user",
            },
          ],
        },
      ],
      role: getUserRole(),
    };
  },
  mounted() {
    if (this.role === "root") {
      this.items[7].children.push(
        {
          id: 15,
          title: this.$t("sidenav.users"),
          icon: "mdi-account-multiple",
          path: "/settings-user-list",
        },
        {
          id: 16,
          title: this.$t("sidenav.controlReport"),
          icon: "mdi-book-open-blank-variant",
          path: "/settings-report",
        }
      );
    }
  },
  methods: {
    go(item) {
      if (item.path) this.$router.push(item.path).catch(() => {});
      window.scrollTo(0, 0);
    },
    collapseSubItems() {
      this.items.map((item) => (item.active = false));
    },
    logout() {
      loginService.logout();
    },
    containsPath(item, path) {
      if (item.path == path) {
        return true;
      } else if (item.children && item.children.length > 0) {
        return item.children.some((child) => child.path == path);
      }
    },
  },
};
</script>

<style scoped>
.menu-item {
  margin-bottom: 20px !important;
}

::v-deep .v-list-item__icon {
  height: 34px !important;
  margin-left: 12px;
  margin-right: 12px !important;
}

.v-list-item:before {
  border-radius: 8px;
}

.v-list-item__content {
  overflow: inherit !important;
}

::v-deep
  .v-application--is-ltr
  .v-list-item__icon:last-of-type:not(:only-child) {
  margin-left: 0px !important;
}

.v-list-group
  .v-list-group__header
  .v-list-item__icon.v-list-group__header__append-icon {
  justify-content: flex-start !important;
}

::v-deep ::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

::v-deep .v-list-group--active .v-list-group__header {
  background-color: #ff585d !important;
  color: #fff !important;
  caret-color: #fff !important;
  border-radius: 8px !important;
}

.v-application--is-ltr
  .v-list--dense.v-list--nav
  .v-list-group--no-action
  > .v-list-group__items
  > .v-list-item {
  padding-left: 30px;
}

::v-deep .grouped-items-no-child .v-list-group__items {
  display: none !important;
}
</style>
