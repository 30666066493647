import Vue from "vue";
import VueRouter from "vue-router";

import loginService from "@/services/login.service";
import HelpLayout from "../layout/HelpLayout.vue";
import PrivateLayout from "../layout/PrivateLayout.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: PrivateLayout,
    children: [
      {
        path: "",
        name: "home",
        //component: HomeView
      },
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("../views/dashboard/Index.vue"),
      },
      {
        path: "/list-public",
        name: "list-public",
        component: () => import("../views/list/PublicIndex.vue"),
      },
      {
        path: "/list-private",
        name: "list-private",
        component: () => import("../views/list/PrivateIndex.vue"),
      },
      {
        path: "/ideas",
        name: "ideas",
        component: () => import("../views/ideas/Index.vue"),
      },
      {
        path: "/evaluate",
        name: "evaluate",
        component: () => import("../views/evaluation/Index.vue"),
      },
      {
        path: "/charts",
        name: "charts",
        component: () => import("../views/charts/Index.vue"),
      },
      {
        path: "/conjuncture",
        name: "conjuncture",
        component: () => import("../views/conjuncture/Index.vue"),
      },
      {
        path: "/catalogs-actor",
        name: "catalogs-actor",
        component: () => import("../views/catalogs/ActorIndex.vue"),
      },
      {
        path: "/catalogs-commission",
        name: "catalogs-commission",
        component: () => import("../views/catalogs/CommissionIndex.vue"),
      },
      {
        path: "/catalogs-law",
        name: "catalogs-law",
        component: () => import("../views/catalogs/LawIndex.vue"),
      },
      {
        path: "/settings-user",
        name: "settings-user",
        component: () => import("../views/settings/UserSettingsIndex.vue"),
      },
      {
        path: "/settings-user-list",
        name: "settings-user-list",
        component: () => import("../views/settings/UserListIndex.vue"),
      },
      {
        path: "/settings-report",
        name: "settings-report",
        component: () => import("../views/settings/ControlReportIndex.vue"),
      },
      {
        path: "/help/platform/feature",
        name: "help-platform-feature",
        component: () =>
          import("../views/help/components/PlatformFeatures.vue"),
      },
      {
        path: "/help/academic/files",
        name: "help-academic-files",
        component: () => import("../views/help/components/AcademicFiles.vue"),
      },
      {
        path: "/about",
        name: "about",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
      },
    ],
  },
  {
    path: "/help",
    name: "help",
    component: HelpLayout,
  },
  {
    path: "/public/login",
    component: () => import("../views/login/Index.vue"),
  },
  {
    path: "/public/restore",
    component: () => import("../views/login/LoginRestore.vue"),
  },
  {
    path: "/public/recover",
    component: () => import("../views/login/LoginRecover.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ["/public/login", "/public/restore", "/public/recover"];

  const authRequired = !publicPages.includes(to.path);

  const loggedIn = loginService.isAuthenticated(false);

  if (authRequired && !loggedIn) {
    return next("/public/login");
  }

  next();
});

export default router;
