import userService from "@/services/user.service";

const defaulDataModule = {
  namespaced: true,
  state: () => ({
    idCountry: null,
  }),
  mutations: {
    setDefaultCountry(state, idCountry) {
      state.idCountry = idCountry;
    },
  },
  getters: {
    defaultCountry(state) {
      return state.idCountry;
    },
  },
  actions: {
    updateDefaultCountry({ commit }, idCountry) {
      userService.updateDefaultCountry(idCountry).then(() => {
        commit("setDefaultCountry", idCountry);
      });
    },
    getDefaultCountry({ commit }) {
      userService.getDefaultCountry().then((d) => {
        commit("setDefaultCountry", d.IdCountry);
      });
    },
  },
};

export default defaulDataModule;
