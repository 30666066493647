<template>
  <div class="d-flex flex-column">
    <section-header
      :title="$t('help.title')"
      :content="$t('help.description')"
    />

    <div class="max-section">
      <v-breadcrumbs :items="items" />

      <v-card flat>
        <v-card-title>
          <h2 class="title-section">{{ $t("help.explore") }}</h2>
        </v-card-title>

        <div class="d-flex flex-column">
          <v-list-item>
            <v-list-item-icon>
              <v-img src="~@/assets/funciones.png" width="50px" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <a href="#/help/platform/feature">
                  <strong>{{ $t("help.platform") }}</strong>
                </a>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ $t("help.platformDescription") }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-img src="~@/assets/academica.png" width="50px" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <a href="#/help/academic/files">
                  <strong>{{ $t("help.academic") }}</strong>
                </a>
              </v-list-item-title>
              <v-list-item-subtitle class="enable-wrap">
                {{ $t("help.academicDescription") }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-img src="~@/assets/comunica.png" width="50px" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <a>
                  <strong>{{ $t("help.communication") }}</strong>
                </a>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ $t("help.communicationDescription") }}
                <a
                  href="mailto:sistema.estrategico@femsa.com.mx"
                  class="force-link-color"
                >
                  <strong>sistema.estrategico@femsa.com.mx</strong>
                </a>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import SectionHeader from "@/components/SectionHeader.vue";

export default {
  name: "hekp-index",
  components: {
    SectionHeader,
  },
  data() {
    return {
      items: [
        {
          text: "Home",
          disabled: false,
          href: "#/dashboard",
        },
        {
          text: this.$t("help.title"),
          disabled: true,
          href: "breadcrumbs_link_1",
        },
      ],
    };
  },
};
</script>

<style scoped>
.max-section {
  max-width: 994px;
  margin: 0 auto;
}

.enable-wrap {
  white-space: normal !important;
  word-wrap: break-word !important;
  overflow-wrap: break-word !important;
  word-break: break-word !important;
  hyphens: auto !important;
  overflow: auto !important;
}

.title-section {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #ff585d;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  color: #ff585d;
  text-decoration: underline;
}

.force-link-color {
  color: #0000ee !important;
  text-decoration: underline;
}

::v-deep .v-breadcrumbs li:last-child a {
  font-weight: bold;
}
</style>
