import http, { e } from "./http";
import loginService from "./login.service";

const userService = {
  getAllUsers() {
    const token = loginService.getRawToken();

    var headers = {
      Authorization: "Bearer " + token,
    };

    return http.get(e(`User/GetAllUsers`), null, headers).then((r) => {
      return r.data;
    });
  },
  getUser(id) {
    const token = loginService.getRawToken();

    var headers = {
      Authorization: "Bearer " + token,
    };

    return http.get(e(`User/${id}`), null, headers).then((r) => {
      return r.data;
    });
  },
  getCurrent() {
    const token = loginService.getRawToken();

    var headers = {
      Authorization: "Bearer " + token,
    };

    return http.get(e(`User/current`), null, headers).then((r) => {
      return r.data;
    });
  },
  updateCurrentUser(data) {
    const token = loginService.getRawToken();

    var headers = {
      Authorization: "Bearer " + token,
    };

    return http.post(e(`User/UpdateCurrent`), data, headers).then((r) => {
      return r.data;
    });
  },
  updatePassword(data) {
    const token = loginService.getRawToken();

    var headers = {
      Authorization: "Bearer " + token,
    };

    return http.post(e(`User/UpdatePassword`), data, headers).then((r) => {
      return r.data;
    });
  },
  updateAvatar(idDocument) {
    const token = loginService.getRawToken();

    var headers = {
      Authorization: "Bearer " + token,
    };

    return http
      .put(
        e(`User/avatar`),
        {
          idDocument,
        },
        headers
      )
      .then((r) => {
        return r.data;
      });
  },
  getLogos() {
    const token = loginService.getRawToken();

    var headers = {
      Authorization: "Bearer " + token,
    };

    return http.get(e(`Configuration/Logos`), null, headers).then((r) => {
      return r.data;
    });
  },
  uploadLogo(file) {
    const token = loginService.getRawToken();

    var headers = {
      Authorization: "Bearer " + token,
    };

    return http.upload(e("User/UploadLogo"), file, headers);
  },
  updateLogo(idUserLogo) {
    const token = loginService.getRawToken();

    var headers = {
      Authorization: "Bearer " + token,
    };

    return http.put(e(`Configuration/UserLogo/${idUserLogo}`), null, headers);
  },
  searchUsers(data) {
    const token = loginService.getRawToken();

    var headers = {
      Authorization: "Bearer " + token,
    };

    return http.post(e("User/Search"), data, headers).then((r) => {
      return r.data;
    });
  },
  updateUser(user) {
    const token = loginService.getRawToken();

    var headers = {
      Authorization: "Bearer " + token,
    };

    return http.post(e("User/Update"), user, headers).then((r) => {
      return r.data;
    });
  },
  createUser(data) {
    const token = loginService.getRawToken();

    var headers = {
      Authorization: "Bearer " + token,
    };

    return http.post(e("User/Create"), data, headers).then((r) => {
      return r.data;
    });
  },
  deleteUser(id) {
    const token = loginService.getRawToken();

    var headers = {
      Authorization: "Bearer " + token,
    };

    return http.remove(e(`User/${id}`), headers).then((r) => {
      return r.data;
    });
  },
  updateDefaultCountry(idCountry) {
    const token = loginService.getRawToken();

    var headers = {
      Authorization: "Bearer " + token,
    };

    return http
      .put(
        e(`User/default/country`),
        {
          idCountry,
        },
        headers
      )
      .then((r) => {
        return r.data;
      });
  },
  getDefaultCountry() {
    const token = loginService.getRawToken();

    var headers = {
      Authorization: "Bearer " + token,
    };

    return http.get(e(`User/default/country`), null, headers).then((r) => {
      return r.data;
    });
  },
};

export default userService;
