import jwt_decode from "jwt-decode";

export const getSERPlusToken = () => {
  const token = localStorage.getItem("serplus");
  return token;
};

const decodeToken = () => {
  const token = localStorage.getItem("serplus");

  const decoded = jwt_decode(token);
  return decoded;
};

export const getUserRole = () => {
  const decoded = decodeToken();
  return decoded.role;
};

export const getIdUser = () => {
  const decoded = decodeToken();
  return decoded.idUser;
};

export const canCapture = () => {
  const decoded = decodeToken();
  return decoded.canCapture;
};

export const canEvaluate = () => {
  const decoded = decodeToken();
  return decoded.canEvaluate;
};

export const canReset = () => {
  const decoded = decodeToken();
  return decoded.canReset;
};

export const canDeleteComments = () => {
  const decoded = decodeToken();
  return decoded.canDeleteComments;
};
