import jwt_decode from "jwt-decode";
import http, { e, eauth } from "./http";

const STORAGE_NAME = "seruser";
const SERPLUS_STORAGE_NAME = "serplus";

const loginService = {
  login: async function (username, password) {
    const formBody = [];
    formBody.push(
      encodeURIComponent("grant_type") + "=" + encodeURIComponent("password")
    );
    formBody.push(
      encodeURIComponent("client_id") +
        "=" +
        encodeURIComponent(process.env.VUE_APP_CLIENT_ID)
    );
    formBody.push(
      encodeURIComponent("client_secret") +
        "=" +
        encodeURIComponent(process.env.VUE_APP_CLIENT_SECRET)
    );
    formBody.push(
      encodeURIComponent("username") + "=" + encodeURIComponent(username)
    );
    formBody.push(
      encodeURIComponent("password") + "=" + encodeURIComponent(password)
    );

    const body = formBody.join("&");

    const headers = {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    };

    try {
      const response = await http.post(eauth("oauth2/token"), body, headers);

      const hasAccessToken = !!response.data.access_token;

      if (hasAccessToken) {
        localStorage.setItem(STORAGE_NAME, JSON.stringify(response.data));

        return {
          success: true,
          message: "",
        };
      }

      return {
        success: false,
      };
    } catch (error) {
      return {
        success: false,
        message: error.error_description,
      };
    }
  },
  requestRestorePassword(username) {
    return http
      .put(e(`User/RequestRestorePassword/${username}`), null, {})
      .then((r) => {
        return r.data;
      });
  },
  restorePassword(data) {
    return http.put(e("User/RestorePassword"), data, {}).then((r) => {
      return r.data;
    });
  },
  getImage() {
    return http
      .get(e(`User/Image?timestamp=${new Date().getTime()}`), null, {})
      .then((d) => {
        return d.data;
      });
  },
  isJwtAlive: function (jwt) {
    const now = Date.now().valueOf() / 1000;

    if (typeof jwt.exp !== "undefined" && jwt.exp < now) return false;
    if (typeof jwt.nbf !== "undefined" && jwt.nbf > now) return false;

    return true;
  },
  isAuthenticated: function () {
    var token = localStorage.getItem(STORAGE_NAME);
    if (token == null) {
      return false;
    }

    var jwt = this.getSessionToken();

    return this.isJwtAlive(jwt);
  },
  getRawToken: function (redirect = true) {
    var token = localStorage.getItem(STORAGE_NAME);
    if (token) {
      var parsedToken = JSON.parse(token);
      return parsedToken.access_token;
    }

    if (redirect) {
      location.href = "/";
    }

    return null;
  },
  getSessionToken: function (redirect = true) {
    var token = localStorage.getItem(STORAGE_NAME);
    if (token) {
      var parsedToken = JSON.parse(token);
      return jwt_decode(parsedToken.access_token);
    }

    if (redirect) {
      location.href = "/";
    }

    return null;
  },
  logout() {
    localStorage.removeItem(STORAGE_NAME);
    localStorage.removeItem(SERPLUS_STORAGE_NAME);
    localStorage.clear();

    location.href = "/";
  },
  getUserData() {
    var jwt = this.getSessionToken();
    return jwt;
  },
  isAdmin() {
    const data = this.getUserData();
    return data.isAdmin;
  },
  isActive() {
    const data = this.getUserData();
    return data.isActive;
  },
  canCapture() {
    const data = this.getUserData();
    return data.capture;
  },
};

export default loginService;
