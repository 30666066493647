import { render, staticRenderFns } from "./PrivateLayout.vue?vue&type=template&id=245e5091&scoped=true&"
import script from "./PrivateLayout.vue?vue&type=script&lang=js&"
export * from "./PrivateLayout.vue?vue&type=script&lang=js&"
import style0 from "./PrivateLayout.vue?vue&type=style&index=0&id=245e5091&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "245e5091",
  null
  
)

export default component.exports