<template>
  <div>
    <!-- expand-on-hover -->
    <v-app-bar app clipped-left fixed elevation="0" outlined height="96">
      <v-toolbar-title
        class="d-flex justify-center icon-title ml-3"
        @click="goToHome"
      >
        <v-img
          src="../assets/serplus-logo-white.png"
          max-width="207"
          class="align-self-center"
        />
      </v-toolbar-title>

      <div class="ml-12 country-select">
        <v-select
          background-color="white"
          v-if="!hideCountry"
          v-model="selectedCountry"
          :placeholder="$t('topnav.countryPlaceholder')"
          dense
          :items="countries"
          :loading="isLoading"
          item-text="Name"
          item-value="IdCountry"
          outlined
          hide-details
        >
          <template v-slot:selection="{ item }">
            <v-list-item-icon style="margin: 8px 0">
              <country-flag :country="item.Code" style="margin-top: -6px" />
            </v-list-item-icon>
            <v-list-item-content class="ml-5">
              <v-list-item-title class="text-body-2">{{
                item.Name
              }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-icon>
              <country-flag :country="item.Code" style="margin-top: -6px" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.Name }}</v-list-item-title>
            </v-list-item-content>
          </template>
        </v-select>
      </div>

      <v-spacer />

      <div class="d-flex flex-row flex-row-reverse">
        <div class="d-flex mr-10">
          <div>
            <v-avatar
              class="mr-3"
              size="60px"
              @click="goToConfig"
              style="cursor: pointer"
            >
              <img alt="Avatar" :src="avatarUrl" />
            </v-avatar>
          </div>
          <div class="d-flex flex-column white--text" style="width: 265px">
            <div class="text-h3 text-truncate">{{ fullName }}</div>
            <div class="text-body-2">{{ role }}</div>
            <div class="area-name-label mt-1">
              FEMSA Corporativo - Asuntos regulatorios
            </div>
          </div>
        </div>

        <div class="mr-4 mt-1">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon color="white" v-bind="attrs" v-on="on">
                <v-icon large>mdi-earth</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-for="(item, i) in locales"
                :key="i"
                @click="changeLocale(item)"
              >
                <v-list-item-title>
                  <country-flag :country="item.code" class="mr-1" />
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <div class="mr-2 mt-1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="!hideHelp"
                v-bind="attrs"
                v-on="on"
                icon
                color="white"
                @click="goToHelp"
              >
                <v-icon large>mdi-help-circle-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("tooltips.topnav.help") }}</span>
          </v-tooltip>
        </div>
      </div>
    </v-app-bar>
    <side-nav v-if="!hideMenu" />
  </div>
</template>

<script>
import catalogService from "@/services/catalog.service";
import userService from "@/services/user";
import { mapActions, mapGetters } from "vuex";
import SideNav from "./SideNav.vue";

export default {
  name: "top-nav",
  components: {
    SideNav,
  },
  props: {
    hideMenu: {
      type: Boolean,
      default: false,
    },
    hideCountry: {
      type: Boolean,
      default: false,
    },
    hideHelp: {
      type: Boolean,
      default: false,
    },
    lang: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    isLoading: false,
    selectedCountry: null,
    fullName: "",
    role: "",
    avatarUrl: "",
    countries: [],
    locales: [
      { name: "Español", code: "mx", locale: "es", id: 1 },
      { name: "English", code: "us", locale: "en", id: 2 },
      { name: "Português", code: "br", locale: "pt", id: 3 },
    ],
  }),
  computed: {
    ...mapGetters("defaultData", ["defaultCountry"]),
  },
  watch: {
    selectedCountry(val) {
      if (val) {
        this.updateDefaultCountry(val);
      }
    },
    defaultCountry: {
      handler(val) {
        this.selectedCountry = val;
      },
      deep: true,
    },
  },
  mounted() {
    this.setUserInfo();
    this.getCountries();
    this.getDefaultCountry();
  },
  methods: {
    ...mapActions("defaultData", ["updateDefaultCountry", "getDefaultCountry"]),
    getCountries() {
      this.isLoading = true;
      catalogService.getCountries().then((data) => {
        this.countries = data;
        this.isLoading = false;
      });
    },
    async setUserInfo() {
      const data = await userService.getProfile();
      this.fullName = `${data.name} ${data.lastName}`.toUpperCase();
      this.role = `${data.companyRole}`.toUpperCase();
      this.avatarUrl =
        data.urlPicture ||
        "https://avatars0.githubusercontent.com/u/9064066?v=4&s=460";
    },
    async changeLocale(item) {
      await userService.updateProfile({ idLanguage: item.id });
      await userService.getProfile();
      this.$router.go(".");
    },
    goToHome() {
      this.$router.push("/dashboard").catch(() => {});
    },
    goToConfig() {
      this.$router.push("/settings-user").catch(() => {});
    },
    goToHelp() {
      this.$router.push("/help").catch(() => {});
    },
  },
};
</script>

<style scoped>
header {
  background: linear-gradient(268deg, #862633 1.22%, #ff585d 88.98%);
}

.icon-title {
  cursor: pointer;
}

.country-select {
  width: 237px;
}

.area-name-label {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

::v-deep
  .v-select.v-text-field--outlined:not(.v-text-field--single-line).v-input--dense
  .v-select__selections {
  padding: 0 !important;
}
</style>
