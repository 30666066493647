import http, { e } from './http'
import loginService from './login.service'

const catalogService = {
    getAgents () {
        const token = loginService.getRawToken()

        var headers = {
            Authorization: 'Bearer ' + token,
        }

        return http.get(e('Catalog/Agents'), null, headers).then(d => {
            return d.data
        })
    },
    getFullTree () {
        const token = loginService.getRawToken()

        var headers = {
            Authorization: 'Bearer ' + token,
        }

        return http.get(e('Catalog/Tree'), null, headers).then(d => {
            return d.data
        })
    },
    getCountries (byUserAccess = false) {
        const token = loginService.getRawToken()

        var headers = {
            Authorization: 'Bearer ' + token,
        }

        return http.get(e(`Catalog/Countries?byUserAccess=${byUserAccess}`), null, headers).then(d => {
            return d.data
        })
    },
    getStates (idCountry) {
        const token = loginService.getRawToken()

        var headers = {
            Authorization: 'Bearer ' + token,
        }

        return http.get(e(`Catalog/States/${idCountry}`), null, headers).then(d => {
            return d.data
        })
    },
    getTowns (idState) {
        const token = loginService.getRawToken()

        var headers = {
            Authorization: 'Bearer ' + token,
        }

        return http.get(e(`Catalog/Towns/${idState}`), null, headers).then(d => {
            return d.data
        })
    },
    getTownsByStates (states) {
        const token = loginService.getRawToken()

        var headers = {
            Authorization: 'Bearer ' + token,
        }

        return http.post(e('Catalog/Towns'), {
            States: states,
        }, headers).then(d => {
            return d.data
        })
    },
    getStatesByCountries (countries) {
        const token = loginService.getRawToken()

        var headers = {
            Authorization: 'Bearer ' + token,
        }

        return http.post(e('Catalog/States'), {
            Countries: countries,
        }, headers).then(d => {
            return d.data
        })
    },
    getAllLegislativeInstances (countries) {
        const token = loginService.getRawToken()

        var headers = {
            Authorization: 'Bearer ' + token,
        }

        return http.post(e('Catalog/AllLegislativeInstances'), {
            Countries: countries,
        }, headers).then(d => {
            return d.data
        })
    },
    getAllBusinessUnits (countries) {
        const token = loginService.getRawToken()

        var headers = {
            Authorization: 'Bearer ' + token,
        }

        return http.post(e('Catalog/BusinessUnits'), {
            tree: countries,
        }, headers).then(d => {
            return d.data
        })
    },
    getBusinessUnits (idCountry) {
        const token = loginService.getRawToken()

        var headers = {
            Authorization: 'Bearer ' + token,
        }

        return http.get(e(`Catalog/BusinessUnits/${idCountry}`), null, headers).then(d => {
            return d.data
        })
    },
    getBusinessUnitsComplete (byUserAccess = false) {
        const token = loginService.getRawToken()

        var headers = {
            Authorization: 'Bearer ' + token,
        }

        return http.get(e(`Catalog/BusinessUnits/Complete?byUserAccess=${byUserAccess}`), null, headers).then(d => {
            return d.data
        })
    },
    getSubBusinessUnitsComplete (byUserAccess = false) {
        const token = loginService.getRawToken()

        var headers = {
            Authorization: 'Bearer ' + token,
        }

        return http.get(e(`Catalog/SubBusinessUnits/Complete?byUserAccess=${byUserAccess}`), null, headers).then(d => {
            return d.data
        })
    },
    getCompleteCategories () {
        const token = loginService.getRawToken()

        var headers = {
            Authorization: 'Bearer ' + token,
        }

        return http.get(e('Catalog/Categories/Complete'), null, headers).then(d => {
            return d.data
        })
    },
    getRootCategories (idBusinessUnit) {
        const token = loginService.getRawToken()

        var headers = {
            Authorization: 'Bearer ' + token,
        }

        return http.get(e(`Catalog/RootCategories/${idBusinessUnit}`), null, headers).then(d => {
            return d.data
        })
    },
    getAllSubBusinessUnits (countries, businessUnits) {
        const token = loginService.getRawToken()

        var headers = {
            Authorization: 'Bearer ' + token,
        }

        return http.post(e('Catalog/SubBusinessUnits'), {
            countries: countries,
            businessUnits: businessUnits,
        }, headers).then(d => {
            return d.data
        })
    },
    getSubBusinessUnits (idCountry, idBusinessUnit) {
        const token = loginService.getRawToken()

        var headers = {
            Authorization: 'Bearer ' + token,
        }

        return http.get(e(`Catalog/SubBusinessUnits/${idCountry}/${idBusinessUnit}`), null, headers).then(d => {
            return d.data
        })
    },
    getCategories (countries, subBusinessUnits, distinct = false) {
        const token = loginService.getRawToken()

        var headers = {
            Authorization: 'Bearer ' + token,
        }

        return http.post(e(`Catalog/Categories?distinct=${distinct}`), {
            countries: countries,
            businessUnits: subBusinessUnits,
        }, headers).then(d => {
            return d.data
        })
    },
    getExpertise () {
        const token = loginService.getRawToken()

        var headers = {
            Authorization: 'Bearer ' + token,
        }

        return http.get(e('Catalog/Expertise'), null, headers).then(d => {
            return d.data
        })
    },
    getImportance () {
        const token = loginService.getRawToken()

        var headers = {
            Authorization: 'Bearer ' + token,
        }

        return http.get(e('Catalog/Importance'), null, headers).then(d => {
            return d.data
        })
    },
    getLawTypes () {
        const token = loginService.getRawToken()

        var headers = {
            Authorization: 'Bearer ' + token,
        }

        return http.get(e('Catalog/LawTypes'), null, headers).then(d => {
            return d.data
        })
    },
    getCommissionTypes () {
        const token = loginService.getRawToken()

        var headers = {
            Authorization: 'Bearer ' + token,
        }

        return http.get(e('Catalog/CommissionTypes'), null, headers).then(d => {
            return d.data
        })
    },
    getLegislativeInstances (idCountry) {
        const token = loginService.getRawToken()

        var headers = {
            Authorization: 'Bearer ' + token,
        }

        return http.get(e(`Catalog/LegislativeInstances/${idCountry}`), null, headers).then(d => {
            return d.data
        })
    },
    getLegislativeProcess (idLegislativeInstance) {
        const token = loginService.getRawToken()

        var headers = {
            Authorization: 'Bearer ' + token,
        }

        return http.get(e(`Catalog/LegislativeProcess/${idLegislativeInstance}`), null, headers).then(d => {
            return d.data
        })
    },
    getLegislativeLocalProcess () {
        const token = loginService.getRawToken()

        var headers = {
            Authorization: 'Bearer ' + token,
        }

        return http.get(e('Catalog/LegislativeLocalProcess'), null, headers).then(d => {
            return d.data
        })
    },
    getNomProcess (idCountry) {
        const token = loginService.getRawToken()

        var headers = {
            Authorization: 'Bearer ' + token,
        }

        return http.get(e(`Catalog/NomProcess/${idCountry}`), null, headers).then(d => {
            return d.data
        })
    },
    getActorTypes () {
        const token = loginService.getRawToken()

        var headers = {
            Authorization: 'Bearer ' + token,
        }

        return http.get(e('Catalog/ActorTypes'), null, headers).then(d => {
            return d.data
        })
    },
    getActorCommissionTypes () {
        const token = loginService.getRawToken()

        var headers = {
            Authorization: 'Bearer ' + token,
        }

        return http.get(e('Catalog/ActorCommissionTypes'), null, headers).then(d => {
            return d.data
        })
    },
    getActorAcademicLevel () {
        const token = loginService.getRawToken()

        var headers = {
            Authorization: 'Bearer ' + token,
        }

        return http.get(e('Catalog/ActorAcademicLevel'), null, headers).then(d => {
            return d.data
        })
    },
    getLegislagtures (idCountry) {
        const token = loginService.getRawToken()

        var headers = {
            Authorization: 'Bearer ' + token,
        }

        if (idCountry == null) {
            return http.get(e('Catalog/Legislatures'), null, headers).then(d => {
                return d.data
            })
        }

        return http.get(e(`Catalog/Legislatures/${idCountry}`), null, headers).then(d => {
            return d.data
        })
    },
    getLegislagturesByState (idCountry, idState) {
        const token = loginService.getRawToken()

        var headers = {
            Authorization: 'Bearer ' + token,
        }

        return http.get(e(`Catalog/Legislatures/${idCountry}/state/${idState}`), null, headers).then(d => {
            return d.data
        })
    },
    getPoliticalParties (idCountry) {
        const token = loginService.getRawToken()

        var headers = {
            Authorization: 'Bearer ' + token,
        }

        if (idCountry == null) {
            return http.get(e('Catalog/PoliticalParties'), null, headers).then(d => {
                return d.data
            })
        }

        return http.get(e(`Catalog/PoliticalParties/${idCountry}`), null, headers).then(d => {
            return d.data
        })
    },
    getIdeaTypes () {
        const token = loginService.getRawToken()

        var headers = {
            Authorization: 'Bearer ' + token,
        }

        return http.get(e('Catalog/IdeaTypes'), null, headers).then(d => {
            return d.data
        })
    },
}
export default catalogService
