<template>
  <v-app>
    <top-nav :hide-menu="true" :hide-country="true" :hide-help="true" />
    <v-main>
      <v-container fluid>
        <help-index />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import HelpIndex from "../views/help/Index.vue";
import TopNav from "./TopNav.vue";

export default {
  name: "help-layout",
  components: {
    TopNav,
    HelpIndex,
  },
};
</script>

<style scoped>
.v-main {
  background-color: #f5f5f5 !important;
}
.container {
  background-color: #f5f5f5 !important;
  padding: 0px;
}
</style>
