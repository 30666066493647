<template>
    <div class="scroll-to-top" v-show="scY > 1500" @click="scrollToTop">
      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none"
           stroke="#ffffff" style="margin: auto; display: block"
           stroke-width="1" stroke-linecap="square" stroke-linejoin="arcs">
        <path d="M18 15l-6-6-6 6"/>
      </svg>
    </div>
</template>
<script>
export default {
  name: 'scroll-to-top',
  data() {
    return {
      scY: 0,
      scTimer: 0,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
        if (this.scTimer) return;
         this.scTimer = setTimeout(() => {
          this.scY = window.scrollY;
          clearTimeout(this.scTimer);
          this.scTimer = 0;
        }, 100);
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }
}
</script>
<style scoped>
.scroll-to-top {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #FF585D;
  opacity: 0.5;
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
}

.scroll-to-top:hover {
  opacity: 1;
  transition: 0.3s;
}
</style>